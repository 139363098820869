import React from 'react';
import { Row } from 'react-bootstrap';
import OilReservoir from './OilReservoir/OilReservoir';
import TotalExtractionPower from './TotalExtractionPower/TotalExtractionPower';
import styled from 'styled-components';

const StyledRow = styled(Row)`
    margin: 0;
    background: ${(props) => props.theme.black[500]};
`;

const InfoRow = () => {
    return (
        <StyledRow>
            <OilReservoir />
            <TotalExtractionPower drillingStarted={true} />
        </StyledRow>
    );
};

export default InfoRow;
