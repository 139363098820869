import React from 'react';
import styled from 'styled-components';
import { layout, LayoutProps, space, SpaceProps } from 'styled-system';

interface OilerIconProps extends SpaceProps, LayoutProps {
    icon: string;
    icon2x?: string;
    icon3x?: string;
    alt?: string;
}

const StyledImg = styled.img<any>`
    ${space}
    ${layout}
`;

const OilerIcon = (props: OilerIconProps) => {
    const { icon, icon2x, alt } = props;
    const iconX2 = icon2x ? ` ${icon2x} 2x  ` : '';

    return (
        <StyledImg
            {...props}
            style={{
                objectFit: 'contain',
            }}
            srcSet={icon + ` 1x, ${iconX2}`}
            alt={alt}
        />
    );
};

export default OilerIcon;
