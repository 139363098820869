import React, { useMemo } from 'react';
import OilerTypography from '@oilerKit/OilerTypography';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
`;

interface DrillingProgressionProps {
    stakeDaysLeft: number;
    noSuffix?: boolean;
}

interface DaysLeft {
    time: number | string | null;
    sufix: string;
}

const DrillingProgressionTimer = (props: DrillingProgressionProps) => {
    const { stakeDaysLeft, noSuffix } = props;
    const SECS_PER_HOUR =
        parseInt(process.env.REACT_APP_DAY_DURATION_IN_SECS!) / 24;
    const SECS_PER_MINUTE = SECS_PER_HOUR / 60;

    const formatTime = (seconds: number) => {
        const hours = Math.floor(Math.abs(seconds) / SECS_PER_HOUR);
        const minutes = Math.floor(
            (Math.abs(seconds) % SECS_PER_HOUR) / SECS_PER_MINUTE,
        );
        return {
            time: `${Math.abs(hours)}h ${Math.abs(minutes)}m`,
            sufix: seconds < 0 ? 'AGO' : noSuffix ? '' : 'LEFT',
        };
    };

    const getFormattedDaysLeft: DaysLeft = useMemo(() => {
        const flooredDaysLeft = Math.floor(stakeDaysLeft);
        let formattedDaysLeft: DaysLeft = {
            time: 0,
            sufix: '',
        };
        if (flooredDaysLeft > 1) {
            formattedDaysLeft.time = flooredDaysLeft;
            formattedDaysLeft.sufix = noSuffix ? 'DAYS' : 'DAYS LEFT';
            return formattedDaysLeft;
        }
        if (flooredDaysLeft === 1) {
            formattedDaysLeft.time = null;
            formattedDaysLeft.sufix = noSuffix ? 'ONE DAY' : 'ONE DAY LEFT';
            return formattedDaysLeft;
        }
        return formatTime(
            stakeDaysLeft *
                parseInt(process.env.REACT_APP_DAY_DURATION_IN_SECS!),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stakeDaysLeft]);

    return (
        <Wrapper>
            <OilerTypography variant={'h4'} weight={'bold'}>
                {getFormattedDaysLeft.time === null
                    ? getFormattedDaysLeft.sufix
                    : getFormattedDaysLeft.time}
            </OilerTypography>
            <OilerTypography variant={'caption'} ml={1}>
                {getFormattedDaysLeft.time === null
                    ? ''
                    : getFormattedDaysLeft.sufix}
            </OilerTypography>
        </Wrapper>
    );
};
export default DrillingProgressionTimer;
