export enum TxState {
    Idle = 'IDLE',
    Pending = 'PENDING',
    Success = 'SUCCESS',
    Fail = 'FAIL',
    Rejected = 'REJECTED',
}

export enum TxType {
    Approval = 'Approval',
    Drill = 'Drill',
    Abandon = 'Abandon_Drilling',
    Cement = 'Cement',
    Harvest = 'Harvest',
    Withdraw = 'Withdraw',
    Unknown = 'Unknown',
}

export interface Transaction {
    type: TxType;
    state: TxState;
    url?: string;
    hash?: string;
    nonce?: number;
    chainId?: number;
}

export interface TransactionsState {
    transactions: Transaction[];
}

export const initialState: TransactionsState = {
    transactions: [],
};
