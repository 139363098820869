import { useEffect } from 'react';
import { STAKING_ADDRESS } from '@config/addressBook';
import firebase from '@lib/firebaseClient';
import { useDispatch } from 'react-redux';
import {
    updateCurrentValues,
    updateStakingConstants,
} from '@features/firebase/firebaseSlice';

export const FirebaseListener = () => {
    const dispatch = useDispatch();

    const firebaseBlockListener = (currentValuesRef: any) => {
        currentValuesRef.on('value', (snapshot: any) => {
            const snp = snapshot.val();
            if (snp) {
                dispatch(updateCurrentValues(snp));
            }
        });
    };

    const firebaseConstantsListener = async (constantsRef: any) => {
        const snapshot = (await constantsRef.once('value')).val();
        if (snapshot) {
            updateStakingConstants(snapshot);
        } else {
            const chainId = process.env.REACT_APP_CHAIN_ID ?? '42';
            console.error(
                `No DB for this address: "${chainId}-${STAKING_ADDRESS}"`,
            );
        }

        constantsRef.on('value', (snapshot: any) => {
            const snp = snapshot.val();
            if (snp) {
                dispatch(updateStakingConstants(snp));
            }
        });
    };

    useEffect(() => {
        const chainId = process.env.REACT_APP_CHAIN_ID ?? '42';
        if (!process.env.REACT_APP_CHAIN_ID)
            console.warn(
                'No REACT_APP_CHAIN_ID set in env. Using "42" as fallback.',
            );

        // References must be strictly equal!
        const currentValuesRef = firebase
            .database()
            .ref(`${chainId}-${STAKING_ADDRESS}`)
            .child('currents');
        firebaseBlockListener(currentValuesRef);

        // References must be strictly equal!
        const constantsRef = firebase
            .database()
            .ref(`${chainId}-${STAKING_ADDRESS}`)
            .child('constants');
        firebaseConstantsListener(constantsRef);

        return () => {
            currentValuesRef.off();
            constantsRef.off();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};
