import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import OilerIcon from '@oilerKit/OilerIcon';
import metamaskIcon from '@oilerKit/assets/icons/metamask.svg';
import check from '@oilerKit/assets/icons/check-mark/check.png';
import check2x from '@oilerKit/assets/icons/check-mark/check@2x.png';
import check3x from '@oilerKit/assets/icons/check-mark/check@3x.png';
import OilerButton from '@oilerKit/OilerButton';
import { formatAddr } from '@utils/formatAddr';
import {
    fetchBalancesAsync,
    initNetworkAsync,
    requestAccountsAsync,
    selectWallet,
} from '@features/wallet/walletSlice';
import { loadCurrentViewAsync } from '@features/contracts-interactions/messengerSlice';
import OilerSpinner from '@oilerKit/OilerSpinner';
import { selectFirebase } from '@features/firebase/firebaseSlice';
import { down, up } from 'styled-breakpoints';
import OilerTypography from '@oilerKit/OilerTypography';
import { selectReservoir } from '@features/reservoir/reservoirSlice';

const Wrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
`;

const Address = styled.div`
    color: ${(props) => props.theme.white[500]};
    margin-right: 7px;
`;

const ConnectWalletWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    ${down('sm')} {
        justify-content: flex-start;
    }
`;

const MetaMaskWrapper = styled.div`
    ${up('sm')} {
        min-height: 126px;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const MetaMask = () => {
    const dispatch = useDispatch();

    const handleConnect = useCallback(async () => {
        dispatch(requestAccountsAsync());
    }, [dispatch]);

    const { isConnected, loading, account, network } =
        useSelector(selectWallet);

    const { loading: firebaseLoading } = useSelector(selectFirebase);

    const { status } = useSelector(selectReservoir);

    useEffect(() => {
        window?.ethereum?.on('accountsChanged', () => window.location.reload());
        window?.ethereum?.on('chainChanged', () => window.location.reload());

        dispatch(requestAccountsAsync());
        dispatch(initNetworkAsync());
    }, [dispatch]);

    useEffect(() => {
        if (account && !firebaseLoading) {
            dispatch(fetchBalancesAsync(account));
            dispatch(loadCurrentViewAsync());
        }
    }, [dispatch, account, firebaseLoading, status]);

    return (
        <MetaMaskWrapper>
            <Wrapper>
                {loading.account && !isConnected ? (
                    <OilerSpinner height={45} mr={10} />
                ) : (
                    <OilerIcon
                        alt="Metamask"
                        icon={metamaskIcon}
                        height={45}
                        mr={10}
                    />
                )}
                <div className="w-100">
                    <ConnectWalletWrapper>
                        {isConnected && (
                            <>
                                <Address>{formatAddr(account)}</Address>
                                <OilerIcon
                                    icon={check}
                                    icon2x={check2x}
                                    icon3x={check3x}
                                    height={20}
                                    alt="Check mark"
                                />
                            </>
                        )}
                    </ConnectWalletWrapper>
                    <OilerTypography>
                        {!loading.network ? network : ''}
                    </OilerTypography>
                </div>
            </Wrapper>
            {isConnected ? null : (
                <OilerButton
                    onClick={handleConnect}
                    disabled={loading.account}
                    fullWidth
                    mt={32}
                >
                    {!loading.account ? 'Connect Wallet' : 'Pending...'}
                </OilerButton>
            )}
        </MetaMaskWrapper>
    );
};

export default MetaMask;
