import React from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import OilerTypography from '../../../oilerKit/OilerTypography';
import { down, up } from 'styled-breakpoints';
import { space } from 'styled-system';
import { useSelector } from 'react-redux';
import { utils } from 'ethers';
import OilerSpinner from '@oilerKit/OilerSpinner';
import { selectFirebase } from '@features/firebase/firebaseSlice';
import { formatNbToSI } from '@utils/formatNbToSI';
import { useTVL } from '@hooks/useTVL';
import OilerTooltip from '@oilerKit/OilerTooltip';
import OilerIcon from '@oilerKit/OilerIcon';
import INFO from '@oilerKit/assets/icons/info/info-tooltip.svg';

const StyledCol = styled(Col)`
    border: 2px solid ${(props) => props.theme.primary['500']};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${space}

    ${up('md')} {
        border-right: 0;
    }
    ${down('sm')} {
        border-bottom: 0;
    }
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    ${down('sm')} {
        flex-direction: column;
    }
    //gap: 5px;
`;

const { formatEther } = utils;

const OilReservoir = () => {
    const { currentValues } = useSelector(selectFirebase);
    const reservoirBalance = currentValues.reservoirBalance || '';
    const TVL = useTVL();

    return (
        <StyledCol md={5} py={4}>
            <Wrapper>
                <OilerTypography variant={'h5'} weight={'light'} mr={1}>
                    OIL Reservoir
                </OilerTypography>
                <OilerTypography
                    variant={'h4'}
                    color={'primary'}
                    weight={'bold'}
                >
                    {reservoirBalance ? (
                        formatNbToSI(formatEther(reservoirBalance)).replace(
                            '.00',
                            '',
                        )
                    ) : (
                        <OilerSpinner />
                    )}{' '}
                    OIL
                </OilerTypography>
            </Wrapper>
            <OilerTypography weight={'light'} display={'block'} mb={3}>
                (0.5% of total supply)
            </OilerTypography>
            <Wrapper>
                <OilerTooltip
                    title="Total Value Locked - the amount of liquidity locked in our Staking contract"
                    placement="top"
                    id="TVL tooltip"
                >
                    <OilerIcon icon={INFO} />
                </OilerTooltip>
                <OilerTypography variant={'h5'} weight={'light'} mr={1} ml={1}>
                    TVL
                </OilerTypography>
                <OilerTypography
                    variant={'h4'}
                    color={'primary'}
                    weight={'bold'}
                >
                    {TVL ? formatNbToSI(TVL.toString()) : <OilerSpinner />} USDC
                </OilerTypography>
            </Wrapper>
        </StyledCol>
    );
};

export default OilReservoir;
