import {
    AnyAction,
    createAsyncThunk,
    createSlice,
    PayloadAction,
    ThunkDispatch,
} from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '@store/index';
import {
    ContractsInteractionsState,
    DrillingState,
    HarvestSidebarState,
    initialState,
    InteractionState,
    Step,
    StepState,
    Views,
} from './types';
import { STAKING_ADDRESS } from '@config/addressBook';
import { BigNumber, ethers } from 'ethers';
import { makeUIToast } from '@features/ui/uiSlice';
import { parseEther } from 'ethers/lib/utils';
import { drillApprovalTxAsync } from './approve';
import { lockTokensAsync } from './lockTokens';
import { ContractType } from '@services/contracts-types';
import { getContractsProvider } from '@services/contracts-provider';
import invariant from 'tiny-invariant';
import {
    abandonDrillingAsync,
    cementDrillingAsync,
    harvestAsync,
    withdrawAsync,
} from './unlockTokens';

const makeDrillSteps = async (
    dispatch: AppDispatch,
    payload: any,
    getState: any,
): Promise<ContractsInteractionsState> => {
    const { stakingConstants } = (getState() as any).firebase;
    const { LPTokenAddress } = stakingConstants;
    invariant(LPTokenAddress, 'No LPTokenAddress set');

    const contractsProvider = getContractsProvider();
    const LPTokenContract = await contractsProvider.findOrCreate(
        ContractType.LPToken,
        LPTokenAddress,
    );
    const userAddr = await contractsProvider.getAccount();
    const givenAllowance = await LPTokenContract.functions.allowance(
        userAddr,
        STAKING_ADDRESS,
    );
    const needsApproval = BigNumber.from(givenAllowance.toString()).lt(
        parseEther(payload.inputAmount),
    );
    const steps = [] as Step[];
    if (needsApproval) {
        steps.push({
            name: 'Approval',
            state: StepState.Idle,
        } as Step);
    }
    steps.push({ name: 'Drill', state: StepState.Idle } as Step);

    const interactionsState = {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
        view: DrillingState.Start,
        harvestSidebarView: HarvestSidebarState.Harvestable,
    } as ContractsInteractionsState;
    return interactionsState;
};

const makeAbandonDrillSteps = async (
    dispatch: AppDispatch,
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [
        { name: 'Abandon Drilling', state: StepState.Idle },
    ] as Step[];

    const interactionsState = {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
        view: DrillingState.Started,
        harvestSidebarView: HarvestSidebarState.Harvestable,
    } as ContractsInteractionsState;

    return interactionsState;
};

const makeCementDrillSteps = async (
    dispatch: AppDispatch,
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [{ name: 'Cement', state: StepState.Idle }] as Step[];

    const interactionsState = {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
        view: DrillingState.Over,
        harvestSidebarView: HarvestSidebarState.Harvestable,
    } as ContractsInteractionsState;

    return interactionsState;
};

const makeHarvestSteps = async (
    dispatch: AppDispatch,
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [{ name: 'Harvest', state: StepState.Idle }] as Step[];

    const interactionsState = {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
        view: DrillingState.Harvest,
        harvestSidebarView: HarvestSidebarState.Harvestable,
    } as ContractsInteractionsState;

    return interactionsState;
};

const makeWithdrawSteps = async (
    dispatch: AppDispatch,
    payload: any,
): Promise<ContractsInteractionsState> => {
    const steps = [{ name: 'Withdraw', state: StepState.Idle }] as Step[];

    const interactionsState = {
        steps,
        stepsNames: steps.map((step: Step) => step.name),
        activeStep: 1,
        state: InteractionState.Active,
        view: DrillingState.Harvest,
        harvestSidebarView: HarvestSidebarState.Vesting,
    } as ContractsInteractionsState;

    return interactionsState;
};

export const processStepsAsync = createAsyncThunk(
    'contracts-interactions/processSteps',
    async (payload: any, thunkApi) => {
        const { steps } = payload;
        let res;
        for (const step of steps) {
            switch (step.name) {
                case 'Approval':
                    res = await thunkApi.dispatch(
                        drillApprovalTxAsync(payload.inputAmount),
                    );
                    break;
                case 'Drill':
                    res = await thunkApi.dispatch(
                        lockTokensAsync({
                            inputAmount: payload.inputAmount,
                            lockedDays: payload.lockedDays,
                        }),
                    );
                    break;
                case 'Abandon Drilling':
                    res = await thunkApi.dispatch(abandonDrillingAsync());
                    break;
                case 'Cement':
                    res = await thunkApi.dispatch(cementDrillingAsync());
                    break;
                case 'Harvest':
                    res = await thunkApi.dispatch(harvestAsync());
                    break;
                case 'Withdraw':
                    res = await thunkApi.dispatch(withdrawAsync());
                    break;
            }
            dispatchTxStatus(res, thunkApi.dispatch);
        }
    },
);

const dispatchTxStatus = (
    res: any,
    dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
) => {
    const dispatchToast = (payload: any) => dispatch(makeUIToast(payload));

    if (res?.hasOwnProperty('error')) {
        dispatchToast({
            content: 'Transaction failed!',
            options: { autoDismiss: true, appearance: 'error' },
        });
        throw new Error('Tx failed');
    } else {
        dispatchToast({
            content: 'Transaction succeeded!',
            options: { autoDismiss: true, appearance: 'success' },
        });
    }
};

export const buildStepsAsync = createAsyncThunk<
    ContractsInteractionsState,
    { interactionName: string; [rest: string]: any },
    {
        dispatch: AppDispatch;
    }
>(
    'contracts-interactions/buildSteps',
    async (payload, { dispatch, getState }) => {
        const { interactionName } = payload;
        let state;
        switch (interactionName) {
            case 'DRILL':
                const { inputAmount, lockedDays } = payload;
                state = await makeDrillSteps(dispatch, payload, getState);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                        inputAmount,
                        lockedDays,
                    }),
                );
                return state;
            case 'ABANDON_DRILLING':
                state = await makeAbandonDrillSteps(dispatch, payload);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                    }),
                );
                return state;
            case 'CEMENT':
                state = await makeCementDrillSteps(dispatch, payload);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                    }),
                );
                return state;
            case 'HARVEST':
                state = await makeHarvestSteps(dispatch, payload);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                    }),
                );
                return state;
            case 'WITHDRAW':
                state = await makeWithdrawSteps(dispatch, payload);
                dispatch(
                    processStepsAsync({
                        steps: state.steps,
                    }),
                );
                return state;
            case 'CLEAR_STEPS':
                return {
                    ...initialState,
                    view: payload.nextView || DrillingState.Loading,
                };
            default:
                console.error(`No such interaction: "${interactionName}"`);
                return initialState;
        }
    },
);

const isWrongNetwork = async (provider: ethers.providers.Web3Provider) => {
    const { chainId } = await provider.getNetwork();
    const appChainId: string = process.env.REACT_APP_CHAIN_ID!;
    invariant(appChainId, 'Please set REACT_APP_CHAIN_ID');
    return chainId.toString() !== appChainId.toString();
};

export const startupCheckAsync = createAsyncThunk(
    'drill/startupCheck',
    async (_, { getState }) => {
        const { firebase } = getState() as any;
        const { stakingConstants, loading: firebaseLoading } = firebase;

        let view = DrillingState.Loading;

        // Not started check
        if (
            !firebaseLoading &&
            stakingConstants?.LPTokenAddress === ethers.constants.AddressZero
        )
            view = DrillingState.NotStarted;

        // Wrong network check
        const contractsProvider = getContractsProvider();
        const provider = contractsProvider.getProvider();
        if (await isWrongNetwork(provider)) view = DrillingState.WrongNetwork;
        return view;
    },
);

export const loadCurrentViewAsync = createAsyncThunk(
    'drill/loadCurrentView',
    async (_, { getState }): Promise<Views> => {
        // return {
        //     view: DrillingState.Harvest,
        //     harvestSidebarView: HarvestSidebarState.Vesting,
        // }; // @@@ REMOVE ME @@@@ TEST ONLY

        const { messenger, reservoir } = getState() as any;
        if (messenger.view === DrillingState.WrongNetwork) {
            return {
                view: DrillingState.WrongNetwork,
                harvestSidebarView: HarvestSidebarState.Unknown,
            } as Views;
        }

        // Started or not check

        const views = {
            view: DrillingState.Loading,
            harvestSidebarView: HarvestSidebarState.Loading,
        } as Views;

        const { status } = reservoir;

        if (status === -1) {
            return views;
        }

        const viewsArray: Views[] = [
            {
                view: DrillingState.NotParticipated,
                harvestSidebarView: HarvestSidebarState.Harvestable,
            },

            {
                view: DrillingState.Over,
                harvestSidebarView: HarvestSidebarState.Harvestable,
            },
            {
                view: DrillingState.Harvest,
                harvestSidebarView: HarvestSidebarState.Harvestable,
            },
            {
                view: DrillingState.Harvest,
                harvestSidebarView: HarvestSidebarState.Vesting,
            },

            {
                view: DrillingState.Harvest,
                harvestSidebarView: HarvestSidebarState.Vesting,
            },
        ];

        return viewsArray[status];

        // if (currentValues.blockNumber >= stakingConstants.stakingEndBlock) {
        //     if (
        //         userStake?.tokenAmount &&
        //         parseFloat(userStake?.tokenAmount) > 0
        //     ) {
        //         views.view = DrillingState.Over;
        //         views.harvestSidebarView = HarvestSidebarState.Harvestable;
        //     } else {
        //         views.view = DrillingState.Harvest;

        //         views.harvestSidebarView =
        //             grantedTokens && grantedTokens > 0
        //                 ? HarvestSidebarState.Vesting
        //                 : HarvestSidebarState.Harvestable;
        //     }
        // } else {
        //     if (Number(userStake?.tokenAmount) === 0) {
        //         views.view = DrillingState.Start;
        //         views.harvestSidebarView = HarvestSidebarState.Harvestable;
        //     } else {
        //         if (
        //             currentValues.blockNumber >=
        //             Number(userStake.startBlock) +
        //                 Number(userStake.lockingPeriodInBlocks)
        //         ) {
        //             views.view = DrillingState.Over;
        //             views.harvestSidebarView = HarvestSidebarState.Harvestable;
        //         } else {
        //             views.view = DrillingState.Started;
        //             views.harvestSidebarView = HarvestSidebarState.Harvestable;
        //         }
        //     }
        // }

        // return views;
    },
);

export const messenger = createSlice({
    name: 'messenger',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(buildStepsAsync.pending, (state, action) => {
                state = initialState;
            })
            .addCase(buildStepsAsync.rejected, (state, action) => {
                state = initialState;
            })
            .addCase(
                buildStepsAsync.fulfilled,
                (_, action: PayloadAction<ContractsInteractionsState>) => ({
                    ...action.payload,
                }),
            )
            .addCase(
                startupCheckAsync.fulfilled,
                (state, action: PayloadAction<DrillingState>) => {
                    if (
                        action.payload !== state.view &&
                        action.payload !== DrillingState.Loading
                    )
                        state.view = action.payload;
                },
            )
            .addCase(
                loadCurrentViewAsync.fulfilled,
                (state, action: PayloadAction<Views>) => {
                    const { view, harvestSidebarView } = action.payload;
                    if (view !== state.view) state.view = view;
                    if (harvestSidebarView !== state.harvestSidebarView)
                        state.harvestSidebarView = harvestSidebarView;
                },
            )
            // Abandon
            .addCase(abandonDrillingAsync.pending, (state) => {
                state.state = InteractionState.Active;
            })
            .addCase(abandonDrillingAsync.rejected, (state) => {
                state.state = InteractionState.Finished;
            })
            .addCase(abandonDrillingAsync.fulfilled, (state) => {
                state.state = InteractionState.Finished;
                state.view = DrillingState.Start;
                state.harvestSidebarView = HarvestSidebarState.Harvestable;
            })
            // Cement
            .addCase(cementDrillingAsync.pending, (state) => {
                state.state = InteractionState.Active;
            })
            .addCase(cementDrillingAsync.rejected, (state) => {
                state.state = InteractionState.Finished;
            })
            .addCase(cementDrillingAsync.fulfilled, (state) => {
                state.state = InteractionState.Finished;
                state.view = DrillingState.Start;
                state.harvestSidebarView = HarvestSidebarState.Harvestable;
            })
            // Stake
            .addCase(lockTokensAsync.pending, (state) => {
                state.state = InteractionState.Active;
            })
            .addCase(lockTokensAsync.rejected, (state) => {
                state.state = InteractionState.Finished;
            })
            .addCase(lockTokensAsync.fulfilled, (state, action) => {
                state.state = InteractionState.Finished;
                state.view = DrillingState.Started;
            })
            .addCase(harvestAsync.rejected, (state) => {
                state.state = InteractionState.Finished;
                state.view = DrillingState.Harvest;
                state.harvestSidebarView = HarvestSidebarState.Harvestable;
            })
            .addCase(harvestAsync.fulfilled, (state) => {
                state.state = InteractionState.Finished;
                state.view = DrillingState.Harvest;
                state.harvestSidebarView = HarvestSidebarState.Vesting;
            })
            .addCase(withdrawAsync.rejected, (state) => {
                state.state = InteractionState.Finished;
                state.view = DrillingState.Harvest;
                state.harvestSidebarView = HarvestSidebarState.Vesting;
            })
            .addCase(withdrawAsync.fulfilled, (state) => {
                state.state = InteractionState.Finished;
                state.view = DrillingState.Harvest;
                state.harvestSidebarView = HarvestSidebarState.Vesting;
            })
            // Approve
            .addCase(drillApprovalTxAsync.pending, (state) => {
                const { activeStep, steps } = state;
                const curStep = steps[activeStep - 1];
                if (curStep) curStep.state = StepState.Pending;
            })
            .addCase(drillApprovalTxAsync.rejected, (state) => {
                const { activeStep, steps } = state;
                const curStep = steps[activeStep - 1];
                if (curStep) {
                    curStep.state = StepState.Fail;
                }
                state.state = InteractionState.Inactive;
            })
            .addCase(drillApprovalTxAsync.fulfilled, (state, action) => {
                const { activeStep, steps } = state;
                const curStep = steps[activeStep - 1];
                if (curStep) {
                    curStep.state = StepState.Success;
                    const isLastStep = activeStep === steps.length;
                    if (!isLastStep) state.activeStep += 1;
                }
            });
    },
});

export const selectMessenger = (state: RootState) => state.messenger;

export default messenger.reducer;
