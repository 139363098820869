export interface FirebaseStakingConstants {
    stakingFundAmount?: string;
    stakingEndBlock?: number;
    LPTokenAddress?: string;
    oilerTokenAddress?: string;
    vestingDuration?: number;
}
export interface FirebaseCurrentValues {
    blockNumber?: number;
    tokenReserve?: string;
    totalRewardPoints?: string;
    totalSupply?: string;
    reservoirBalance?: string;
    poolReserve0?: string;
    poolReserve1?: string;
    oilPrice?: string;
    lpTokensLocked?: string;
    collateralReserves?: string; // Formatted
    tokenReserves?: string; // Formatted
}

export interface FirebaseState {
    stakingConstants: FirebaseStakingConstants;
    currentValues: FirebaseCurrentValues;
    loading: boolean;
}

export const initialState: FirebaseState = {
    stakingConstants: {},
    currentValues: {},
    loading: true,
};
