import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';
import { fetchBalancesAsync } from '@features/wallet/walletSlice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';

const unlockTokens = async () => {
    const contractsProvider = getContractsProvider();
    const userAddr = await contractsProvider.getAccount();
    const stakingContract = await contractsProvider.findOrCreate(
        ContractType.Staking,
    );

    const stakes = await stakingContract.stakes(userAddr);
    if (stakes?.lockingPeriodInBlocks === 0) {
        console.error('There is currently NO staking happening');
        return;
    }

    return stakingContract.unlockTokens();
};

export const abandonDrillingAsync = createAsyncThunk(
    'drill/abandon',
    async (_, { dispatch, getState }) => {
        const { account } = (getState() as any).wallet;
        const tx = await unlockTokens();
        dispatch(addTransactionAsync({ ...tx, type: TxType.Abandon }));
        await tx.wait();
        dispatch(fetchBalancesAsync(account));
    },
);

export const cementDrillingAsync = createAsyncThunk(
    'drill/cement',
    async (_, { dispatch, getState }) => {
        const { account } = (getState() as any).wallet;
        const tx = await unlockTokens();
        dispatch(addTransactionAsync({ ...tx, type: TxType.Cement }));
        await tx.wait();
        dispatch(fetchBalancesAsync(account));
    },
);

export const harvestAsync = createAsyncThunk(
    'drill/harvest',
    async (_, { dispatch, getState }) => {
        const { account } = (getState() as any).wallet;
        const contractsProvider = getContractsProvider();
        const stakingContract = await contractsProvider.findOrCreate(
            ContractType.Staking,
        );
        const tx = await stakingContract.getRewards();
        dispatch(addTransactionAsync({ ...tx, type: TxType.Harvest }));
        await tx.wait();
        dispatch(fetchBalancesAsync(account));
    },
);

export const withdrawAsync = createAsyncThunk(
    'drill/withdraw',
    async (_, { dispatch, getState }) => {
        const { account } = (getState() as any).wallet;
        const contractsProvider = getContractsProvider();
        const stakingContract = await contractsProvider.findOrCreate(
            ContractType.Staking,
        );
        const tx = await stakingContract.release();
        dispatch(addTransactionAsync({ ...tx, type: TxType.Withdraw }));
        await tx.wait();
        dispatch(fetchBalancesAsync(account));
    },
);
