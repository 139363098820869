import { BigNumberish } from 'ethers';
import { Network } from '@utils/getNameFromNetId';

export interface Loaders {
    account: boolean;
    network: boolean;
    balances: boolean;
    stakingEndsInDays: boolean;
}

export interface Balances {
    [asset: string]: BigNumberish;
}

export interface WalletState {
    loading: Loaders;
    isConnected: boolean;
    account: string; // Ethereum address
    network: Network;
    balances: Balances;
    stakingEndsInDays: number;
    stakingStartBlock: number;
    stakingEndBlock: number;
}

export const initialState: WalletState = {
    loading: {
        account: false,
        network: false,
        balances: false,
        stakingEndsInDays: false,
    },
    isConnected: false,
    account: '',
    network: Network.Unknown,
    balances: {} as Balances,
    stakingEndsInDays: 0,
    stakingStartBlock: 0,
    stakingEndBlock: 0,
};
