import React from 'react';
import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { space } from 'styled-system';
import { up } from 'styled-breakpoints';
import { OilerAlert } from '@oilerKit/OilerAlert';
import OilerIcon from '@oilerKit/OilerIcon';
import CONGRATS from '@assets/congrats.svg';
import OilerTypography from '@oilerKit/OilerTypography';
// @ts-ignore
import vid from '@assets/3-filling-barrels.mp4';

const StyledCol = styled(Col)`
    border: 2px solid ${(props) => props.theme.primary['500']};
    border-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    ${space}

    ${up('md')} {
        border-right: 0;
    }
`;

const Congratulations = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & img {
        width: 100%;
    }
`;

const CongratulationsWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 0 10px;
`;

const StyledVideo = styled.video`
    width: 70%;
`;

const DidntParticipated = () => {
    return (
        <StyledCol md={8} pb={4} pt={4}>
            <CongratulationsWrapper>
                <Congratulations>
                    <OilerIcon height={20} icon={CONGRATS} />
                    <OilerTypography
                        variant={'h5'}
                        weight={'bold'}
                        align={'center'}
                        color={'secondary'}
                        mt={2}
                    >
                        Oiler Staking Expedition is over
                    </OilerTypography>
                    <StyledVideo playsInline autoPlay muted>
                        <source src={vid} type="video/mp4" />
                    </StyledVideo>
                </Congratulations>
            </CongratulationsWrapper>

            <OilerAlert variant={'info'} show>
                It looks like you didn’t participate in the expedition, so you
                don’t have any Harvest.
            </OilerAlert>
        </StyledCol>
    );
};

export default DidntParticipated;
