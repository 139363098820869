import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { initialState, StakingUserData } from './types';
import { calculateStakingRewardPoints } from '@lib/math/calculations/stakingRewardPoints';
import { calculateAPY } from '@math/calculations/APY';
import { formatEther, parseEther } from 'ethers/lib/utils';
import { computeHarvestShare } from '@lib/math/calculations/harvest';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';
import {
    FirebaseCurrentValues,
    FirebaseStakingConstants,
} from '@features/firebase/types';
import { BigNumber } from 'ethers/lib/ethers';
import { HarvestSidebarState } from '@features/contracts-interactions/types';

export const fetchStakes = async () => {
    const contractsProvider = getContractsProvider();
    const walletAddr = await contractsProvider.getAccount();
    const stakingContract = await contractsProvider.findOrCreate(
        ContractType.Staking,
    );

    const stakes = await stakingContract.stakes(walletAddr);
    const rewardPointsEarned = await stakingContract.rewardPointsEarned(
        walletAddr,
    );

    return {
        stakes,
        rewardPointsEarned,
    };
};

export const fetchStakingStats = async (getState: () => any) => {
    const state = getState();
    const { currentValues, stakingConstants } = state.firebase;
    const { account } = state.wallet;

    return {
        tokenReserve: currentValues.tokenReserve,
        totalSupply: currentValues.totalSupply,
        stakingFundAmount: stakingConstants.stakingFundAmount,
        totalRewardPoints: currentValues.totalRewardPoints,
        userAddress: account,
    };
};

const getVestedAmount = (
    currentValues: FirebaseCurrentValues,
    stakingConstants: FirebaseStakingConstants,
    vestingDuration: BigNumber,
    harvestableOil: BigNumber,
): BigNumber => {
    const { blockNumber } = currentValues;
    const { stakingEndBlock } = stakingConstants;

    if (blockNumber! >= stakingEndBlock! + Number(vestingDuration)) {
        return harvestableOil;
    }
    return harvestableOil
        .mul(blockNumber! - stakingEndBlock!)
        .div(vestingDuration);
};

export const getStakingUserData = createAsyncThunk(
    'drill/getStakingUserData',
    async (address: any, { getState }) => {
        const { totalRewardPoints, stakingFundAmount } =
            await fetchStakingStats(getState);

        const rewardsFormatted = totalRewardPoints || '0';
        const url = `${process.env.REACT_APP_CLOUD_URL}/getStakingUserData?address=${address}&rewards=${rewardsFormatted}`;

        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const stakingData: StakingUserData = await response.json();

        const {
            grantedTokens,
            rewardPointsEarned,
            harvest,
            harvestShare,
            stakes,
            releasedTokens,
            vestingDuration,
            vestedAmount,
            status,
        } = stakingData;

        return {
            totalRewardPoints: totalRewardPoints?.toString(),
            rewardPointsEarned: BigNumber.from(rewardPointsEarned).toString(),
            grantedTokens: BigNumber.from(grantedTokens).toString(),
            harvestShare: harvestShare,
            estimatedHarvest: harvest.toString(),
            stakingFundAmount: stakingFundAmount?.toString(),
            userStake: {
                tokenAmount: BigNumber.from(stakes[0]).toString(),
                lockingPeriodInBlocks: BigNumber.from(stakes[1]).toString(),
                startBlock: BigNumber.from(stakes[2]).toString(),
                expectedStakingRewardPoints: BigNumber.from(
                    stakes[3],
                ).toString(),
            },
            harvestableOil: parseEther(harvest.toString()).toString(),
            withdrawnAmount: BigNumber.from(releasedTokens).toString(),
            vestingDuration: BigNumber.from(vestingDuration).toString(),
            vestedAmount: BigNumber.from(vestedAmount).toString(),
            withdrawableAmount: BigNumber.from(vestedAmount)
                .sub(releasedTokens)
                .toString(),
            status: status,
        };
    },
);

export const fetchDrillingStatsAsync = createAsyncThunk(
    'reservoir/fetchDrillingStats',
    async (_, { getState }) => {
        const { totalRewardPoints, stakingFundAmount } =
            await fetchStakingStats(getState);
        let { stakes: userStake, rewardPointsEarned } = await fetchStakes();

        const userRewardPoints = calculateStakingRewardPoints(
            userStake.tokenAmount,
            userStake.lockingPeriodInBlocks,
        );

        let harvestShare = computeHarvestShare(
            totalRewardPoints,
            rewardPointsEarned,
        );
        const harvest =
            totalRewardPoints?.toString() !== '0'
                ? Math.round(
                      ((((userRewardPoints as any) /
                          totalRewardPoints) as any) *
                          stakingFundAmount) as any,
                  ).toString(16)
                : '0';
        const estimatedHarvest =
            harvest &&
            harvest.toString() !== '0' &&
            userRewardPoints?.toString() !== '0'
                ? formatEther(`0x${harvest}`)
                : '0';

        // @ts-ignore
        const { account } = getState()?.wallet;
        // @ts-ignore
        const { harvestSidebarView } = getState()?.messenger;

        const contractsProvider = getContractsProvider();
        const stakingContract = await contractsProvider.findOrCreate(
            ContractType.Staking,
        );
        // 500,000 is constant OIL RESERVOIR
        const harvestableOil =
            harvestSidebarView === HarvestSidebarState.Harvestable
                ? BigNumber.from(parseEther((500000 * harvestShare).toString()))
                : await stakingContract.grantedTokens(account);
        if (harvestSidebarView === HarvestSidebarState.Vesting) {
            harvestShare = Number(formatEther(harvestableOil)) / 500000;
            rewardPointsEarned = parseEther(harvestShare.toString())
                .mul(totalRewardPoints)
                .div(parseEther('1'));
        }
        const withdrawnAmount = await stakingContract.releasedTokens(account);
        const { firebase } = getState() as any;
        const { currentValues, stakingConstants } = firebase;
        const vestingDuration = await stakingContract.vestingDuration();
        const vestedAmount = getVestedAmount(
            currentValues,
            stakingConstants,
            vestingDuration,
            harvestableOil,
        );
        const withdrawableAmount = vestedAmount
            .sub(BigNumber.from(withdrawnAmount))
            .toString();
        return {
            totalRewardPoints: totalRewardPoints.toString(),
            rewardPointsEarned: rewardPointsEarned.toString(),
            harvestShare: Number(harvestShare),
            estimatedHarvest: estimatedHarvest.toString(),
            stakingFundAmount: stakingFundAmount.toString(),
            userStake: {
                tokenAmount: userStake?.tokenAmount.toString(),
                lockingPeriodInBlocks:
                    userStake?.lockingPeriodInBlocks.toString(),
                startBlock: userStake?.startBlock.toString(),
                expectedStakingRewardPoints:
                    userStake?.expectedStakingRewardPoints.toString(),
            },
            harvestableOil: harvestableOil.toString(),
            withdrawnAmount: withdrawnAmount.toString(),
            withdrawableAmount: withdrawableAmount.toString(),
            vestedAmount: vestedAmount.toString(),
        };
    },
);

export const fetchAPYAsync = createAsyncThunk(
    'reservoir/fetchApy',
    async (_, { getState }) => {
        const {
            tokenReserve,
            totalSupply,
            stakingFundAmount,
            totalRewardPoints,
        } = await fetchStakingStats(getState);
        const { stakes } = await fetchStakes();

        return calculateAPY({
            lpTokenTotalSupply: totalSupply,
            stakingFundAmount,
            totalRewardPoints,
            lpTokensAmount: stakes.tokenAmount,
            lockingPeriodInBlocks: stakes.lockingPeriodInBlocks,
            tokenReserve,
        });
    },
);

export const reservoir = createSlice({
    name: 'reservoir',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAPYAsync.pending, (state) => {
                state.APY = undefined;
            })
            .addCase(fetchAPYAsync.rejected, (state) => {
                state.APY = undefined;
            })
            .addCase(fetchAPYAsync.fulfilled, (state, action) => {
                state.APY = action.payload;
            })
            .addCase(getStakingUserData.pending, (state) => ({
                ...state,
                APY: state.APY,
                reservoir: state.reservoir,
            }))
            .addCase(getStakingUserData.rejected, (state) => ({
                ...state,
                APY: state.APY,
                reservoir: state.reservoir,
            }))
            .addCase(getStakingUserData.fulfilled, (state, action) => {
                state.totalRewardPoints = action.payload.totalRewardPoints;
                state.rewardPointsEarned = action.payload.rewardPointsEarned;
                state.harvestShare = action.payload.harvestShare;
                state.estimatedHarvest = action.payload.estimatedHarvest;
                state.stakingFundAmount = action.payload.stakingFundAmount;
                state.userStake = action.payload.userStake;
                state.grantedTokens = action.payload.grantedTokens;
                state.harvestableOil = action.payload.harvestableOil;
                state.withdrawnAmount = action.payload.withdrawnAmount;
                state.withdrawableAmount = action.payload.withdrawableAmount;
                state.vestingDuration = action.payload.vestingDuration;
                state.vestedAmount = action.payload.vestedAmount;
                state.status = action.payload.status;
            });
    },
});

export const selectReservoir = (state: RootState) => state.reservoir;

export default reservoir.reducer;
