import React from 'react';
import styled from 'styled-components';
import { Alert, AlertProps } from 'react-bootstrap';
import OilerIcon from '@oilerKit/OilerIcon';
import iIcon from '@oilerKit/assets/icons/info/i-dark.svg';
import { space, SpaceProps } from 'styled-system';

const StyledBootstrapAlert = styled<any>(Alert)`
    background: ${(props) => props.theme.primary['900']};
    color: ${(props) => props.theme.primary['600']};
    font-weight: ${(props) => props.theme.fontWeight.semiBold};
    border: 1px solid ${(props) => props.theme.primary['700']};
    border-radius: 0;
    display: flex;
    padding: 0;
    margin-bottom: 0;
    width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
    > .close {
        color: #010101;
        opacity: 1;
        font-size: 30px;
        font-weight: ${(props) => props.theme.fontWeight.light};
    }
    ${space}
`;

const Accent = styled.div`
    background: ${(props) => props.theme.primary['700']};
    //min-width: 20px;
    //height: 100%;
    display: flex;
    align-items: center;

    & object {
        color: red;
    }
`;

const Content = styled.div`
    padding: 0.75rem 1.25rem;
`;

interface OilerAlertProps extends AlertProps, SpaceProps {
    children?: JSX.Element | JSX.Element[] | string;
    fullWidth?: boolean;
    icon?: string;
    variant?: 'info';
}

export const OilerAlert = (props: OilerAlertProps) => {
    const { variant, icon } = props;
    const variantIcon = variant === 'info' ? iIcon : '';
    return (
        // @ts-ignore
        <StyledBootstrapAlert {...props}>
            {icon || variantIcon ? (
                <Accent>
                    <OilerIcon
                        icon={icon ? icon : variantIcon}
                        height={35}
                        px={3}
                    />
                </Accent>
            ) : (
                <Accent />
            )}

            <Content>{props.children}</Content>
        </StyledBootstrapAlert>
    );
};

export const OilerAlertHeading = styled(Alert.Heading)``;
export const OilerAlertLink = styled(Alert.Link)``;
