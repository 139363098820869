import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { initialState } from './types';

export const ui = createSlice({
    name: 'ui',
    initialState,
    reducers: {
        makeUIToast: (toastState, action) => {
            toastState.content = action.payload.content;
            toastState.options = action.payload.options;
            toastState.callback = action.payload.callback;
        },
    },
});

export const { makeUIToast } = ui.actions;

export const selectUI = (state: RootState) => state.ui;

export default ui.reducer;
