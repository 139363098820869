import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { calculateStakingRewardPoints } from './stakingRewardPoints';
import { getBlockDuration } from '@utils/get-block-duration';
import { BigNumber as BigNumerLegacy } from 'bignumber.js';

export function calculateAPY(options: {
    lpTokenTotalSupply: BigNumberish;
    stakingFundAmount: BigNumberish;
    lpTokensAmount: BigNumberish;
    totalRewardPoints: BigNumberish;
    lockingPeriodInBlocks: number;
    tokenReserve: BigNumberish;
}) {
    const {
        lpTokenTotalSupply,
        stakingFundAmount,
        lpTokensAmount,
        lockingPeriodInBlocks,
        totalRewardPoints,
        tokenReserve,
    } = options;

    if (
        !lpTokenTotalSupply ||
        !stakingFundAmount ||
        !lockingPeriodInBlocks ||
        !lpTokensAmount ||
        !tokenReserve
    )
        return '0';

    const blockDuration = getBlockDuration();
    const estimatedRewardPoints = calculateStakingRewardPoints(
        lpTokensAmount,
        lockingPeriodInBlocks,
    );

    if (
        lpTokenTotalSupply.toString() === '0' ||
        totalRewardPoints.toString() === '0'
    )
        return '';

    const LPValueInTokens = BigNumber.from(tokenReserve)
        .mul(2)
        .div(lpTokenTotalSupply);

    const estimatedRewards = estimatedRewardPoints
        .mul(stakingFundAmount)
        .div(totalRewardPoints);

    const userStakeValue = LPValueInTokens.mul(lpTokensAmount);

    const days =
        (lockingPeriodInBlocks * blockDuration) /
        parseInt(process.env.REACT_APP_DAY_DURATION_IN_SECS!);

    const estimatedRewardsStakeRatio = new BigNumerLegacy(
        estimatedRewards.toString(),
    ).div(new BigNumerLegacy(userStakeValue.toString()));

    const APY =
        Math.pow(1 + estimatedRewardsStakeRatio.toNumber(), 365 / days) - 1;

    return APY.toString();
}
