import React, { useCallback } from 'react';
import { Row } from 'react-bootstrap';
import styled from 'styled-components';
import Harvestable from './Harvestable/Harvestable';
import { useSelector } from 'react-redux';
import { selectMessenger } from '@features/contracts-interactions/messengerSlice';
import {
    DrillingState,
    HarvestSidebarState,
} from '@features/contracts-interactions/types';
import DidntParticipated from '@components/DrillingRow/DidntParticipated';

const StyledRow = styled(Row)`
    margin: 0;
    background: ${(props) => props.theme.black[500]};
`;

const WrongNetwork = React.lazy(
    () => import('@components/DrillingRow/WrongNetwork'),
);
const Loading = React.lazy(() => import('./Loading'));
const StartDrilling = React.lazy(() => import('./StartDrilling/StartDrilling'));
const DrillingStarted = React.lazy(
    () => import('./DrillingStarted/DrillingStarted'),
);
const DrillingOver = React.lazy(() => import('./DrillingOver/DrillingOver'));
const Harvest = React.lazy(
    () => import('@components/DrillingRow/Harvest/Harvest'),
);
const Vesting = React.lazy(
    () => import('@components/DrillingRow/Harvestable/Vesting'),
);

const DrillingRow = () => {
    const { view, harvestSidebarView } = useSelector(selectMessenger);

    const renderView = useCallback(() => {
        switch (view) {
            case DrillingState.WrongNetwork:
                return <WrongNetwork />;
            case DrillingState.Loading:
                return <Loading />;
            case DrillingState.Start:
                return <StartDrilling />;
            case DrillingState.Started:
                return <DrillingStarted />;
            case DrillingState.Over:
                return <DrillingOver />;
            case DrillingState.Harvest:
                return <Harvest />;
            case DrillingState.NotParticipated:
                return <DidntParticipated />;
            default:
                return <StartDrilling />;
        }
    }, [view]);

    const renderHarvestSidebar = useCallback(() => {
        switch (harvestSidebarView) {
            case HarvestSidebarState.Harvestable:
                return <Harvestable />;
            case HarvestSidebarState.Vesting:
                return <Vesting />;
            default:
                return <Harvestable />;
        }
    }, [harvestSidebarView]);

    return (
        <StyledRow>
            {renderView()}
            {renderHarvestSidebar()}
        </StyledRow>
    );
};

export default DrillingRow;
