export enum StepState {
    Idle = 'IDLE',
    Pending = 'PENDING',
    Success = 'SUCCESS',
    Fail = 'FAIL',
    Rejected = 'REJECTED',
}

export interface Step {
    name: string;
    state: StepState;
}

export enum InteractionState {
    Inactive = 'INACTIVE',
    Active = 'ACTIVE',
    Finished = 'FINISHED',
}

export enum DrillingState {
    WrongNetwork = 'WRONG_NETWORK',
    Loading = 'LOADING',
    NotStarted = 'NOT_STARTED',
    Start = 'START',
    Started = 'STARTED',
    Over = 'OVER',
    Harvest = 'HARVEST',
    NotParticipated = 'NOT_PARTICIPATED',
}

export enum HarvestSidebarState {
    Loading = 'LOADING',
    Harvestable = 'HARVESTABLE',
    Vesting = 'VESTING',
    Unknown = 'UNKNOWN',
}

export interface ContractsInteractionsState {
    activeStep: number;
    steps: Step[];
    stepsNames: string[];
    state: InteractionState;
    view: DrillingState;
    harvestSidebarView: HarvestSidebarState;
}

export const initialState: ContractsInteractionsState = {
    activeStep: 0,
    steps: [],
    stepsNames: [],
    state: InteractionState.Inactive,
    view: DrillingState.Loading,
    harvestSidebarView: HarvestSidebarState.Loading,
};

export interface Views {
    view: DrillingState;
    harvestSidebarView: HarvestSidebarState;
}
