import React from 'react';
import OilerIcon from '@oilerKit/OilerIcon';
import caution from '@oilerKit/assets/img/caution.png';

interface ErrorBoundaryProps {}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<
    ErrorBoundaryProps,
    ErrorBoundaryState
> {
    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        console.error(error);
        return { hasError: true };
    }

    componentDidCatch(error: any) {
        console.error(error);
        this.setState({ hasError: true });
    }

    render() {
        if (this.state.hasError) {
            return (
                <OilerIcon
                    icon={caution}
                    width={'100%'}
                    maxWidth={500}
                    display={'flex'}
                    m={'auto'}
                    mt={'20%'}
                    alt={'Error message'}
                />
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
