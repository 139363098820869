import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

if (
    !process.env.REACT_APP_FIREBASE_API_KEY ||
    !process.env.REACT_APP_FIREBASE_AUTH_DOMAIN ||
    !process.env.REACT_APP_FIREBASE_DATABASE_URL ||
    !process.env.REACT_APP_FIREBASE_PROJECT_ID ||
    !process.env.REACT_APP_FIREBASE_STORAGE_BUCKET ||
    !process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID ||
    !process.env.REACT_APP_FIREBASE_APP_ID
) {
    throw new Error('Please set REACT_APP_FIREBASE_* in env');
}

const clientCredentials = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

if (!firebase.apps.length)
    firebase.initializeApp(clientCredentials).auth().signInAnonymously();

export default firebase;
