import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';
import Logosvg from '@oilerKit/themes/img/OilerLogo/oiler-logo.svg';

const StyledBootstrapNavBrand = styled(Navbar.Brand)`
    position: relative;
    margin-top: 38px;
    margin-bottom: 24px;
    align-self: flex-start;
    color: ${(props) => props.theme.black['500']};

    > img {
        height: 75px;
    }
`;

const Logo = () => {
    return (
        <StyledBootstrapNavBrand href="/">
            <img alt="Oiler logo" src={Logosvg} />
        </StyledBootstrapNavBrand>
    );
};

export default Logo;
