import React, { useMemo } from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import OilerTypography from '@oilerKit/OilerTypography';
import { down } from 'styled-breakpoints';
import { space } from 'styled-system';
import { useSelector } from 'react-redux';
import { selectReservoir } from '@features/reservoir/reservoirSlice';
import { formatEther } from '@ethersproject/units';
import { formatNbToSI } from '@utils/formatNbToSI';
import OilerSpinner from '@oilerKit/OilerSpinner';
import OilerIcon from '@oilerKit/OilerIcon';
import INFO from '@oilerKit/assets/icons/info/info-tooltip.svg';
import OilerTooltip from '@oilerKit/OilerTooltip';

const StyledCol = styled(Col)`
    border: 2px solid ${(props) => props.theme.primary['500']};
    display: flex;
    flex-direction: column;
    gap: 20px;
    ${space}
    ${down('sm')} {
        gap: 10px;
    }
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    ${down('sm')} {
        flex-direction: column;
    }
`;

interface TotalExtractionPowerProps {
    drillingStarted?: boolean;
}

const TotalExtractionPower = (props: TotalExtractionPowerProps) => {
    const { drillingStarted = false } = props;
    const { totalRewardPoints, harvestShare } = useSelector(selectReservoir);

    const TotalExtractionPower = totalRewardPoints
        ? formatNbToSI(formatEther(totalRewardPoints))
        : null;

    const yourHarvestShare = useMemo(
        () =>
            totalRewardPoints
                ? (harvestShare * 100).toFixed(
                      harvestShare * 100 > 0 && harvestShare * 100 < 0.01
                          ? 10
                          : 2,
                  )
                : null,
        [totalRewardPoints, harvestShare],
    );

    return (
        <StyledCol md={7} py={4}>
            <Wrapper>
                <>
                    <OilerTooltip
                        title="This is a sum of extraction power of all Oiler Expedition participants who drilled."
                        placement="top"
                        id="Total extraction power tooltip"
                    >
                        <OilerIcon icon={INFO} />
                    </OilerTooltip>
                    <OilerTypography variant={'h5'} weight={'light'}>
                        Total Extraction Power
                    </OilerTypography>
                    <OilerTypography variant={'h4'}>
                        {TotalExtractionPower ?? <OilerSpinner />}
                    </OilerTypography>
                </>
            </Wrapper>
            <Wrapper>
                {drillingStarted ? (
                    <>
                        <OilerTooltip
                            title="Your Harvest Share = Your Extraction Power / Total Extraction Power"
                            placement="top"
                            id="Your harvest share tooltip"
                        >
                            <OilerIcon icon={INFO} />
                        </OilerTooltip>
                        <OilerTypography variant={'h5'} weight={'light'}>
                            Your Harvest Share
                        </OilerTypography>
                        <OilerTypography variant={'h4'}>
                            {yourHarvestShare ?? <OilerSpinner />}%
                        </OilerTypography>
                    </>
                ) : null}
            </Wrapper>
        </StyledCol>
    );
};

export default TotalExtractionPower;
