import invariant from 'tiny-invariant';
import { STAKING_ADDRESS } from '@config/addressBook';
import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';
import { BigNumberish, utils } from 'ethers';

export const drillApprovalTxAsync = createAsyncThunk(
    'drill/approval-tx',
    async (inputAmount: BigNumberish, { dispatch, getState }) => {
        const { stakingConstants } = (getState() as any).firebase;
        const { LPTokenAddress } = stakingConstants;
        invariant(LPTokenAddress, 'No LPTokenAddress set.');

        const LPTokenContract = await getContractsProvider().findOrCreate(
            ContractType.LPToken,
            LPTokenAddress,
        );
        const approvalTx = await LPTokenContract.functions.approve(
            STAKING_ADDRESS,
            utils.parseEther(inputAmount.toString()),
        );
        dispatch(addTransactionAsync({ ...approvalTx, type: TxType.Approval }));
        await approvalTx.wait();
        return approvalTx?.hash;
    },
);
