import React from "react";
import { Container } from "react-bootstrap";
import styled from "styled-components";

const StyledBootstrapContainer = styled(Container)`
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  flex-grow: 1;
  padding: 0 0;
`;

const OilerContainer: Container = (props: any) => {
  return (
    <StyledBootstrapContainer {...props}>
      {props.children}
    </StyledBootstrapContainer>
  );
};

export default OilerContainer;
