import React, { ReactElement } from 'react';
import { OverlayTrigger, Tooltip, TooltipProps } from 'react-bootstrap';
import styled from 'styled-components';

const StyledBootstrapTooltip = styled(Tooltip)`
    &&& {
        opacity: 1;
        font-family: 'Montserrat', sans-serif;
    }
    &&& .arrow:before {
        border-top-color: ${(props) => props.theme.primary['500']};
    }
    & .tooltip-inner {
        border-radius: 0;
        color: ${(props) => props.theme.black['500']};
        background: ${(props) => props.theme.primary['500']};
        & .arrow {
            background: ${(props) => props.theme.primary['500']};
        }
    }
`;

interface OilerTooltipProps extends TooltipProps {
    children: ReactElement;
    placement: 'top' | 'right' | 'bottom' | 'left';
    title: string;
}

const OilerTooltip = (props: OilerTooltipProps) => {
    const { children, placement, title, show = true } = props;

    return (
        <OverlayTrigger
            key={placement}
            placement={placement}
            overlay={
                show ? (
                    <StyledBootstrapTooltip {...props}>
                        {title}
                    </StyledBootstrapTooltip>
                ) : (
                    <span></span>
                )
            }
        >
            <span> {children}</span>
        </OverlayTrigger>
    );
};

export default OilerTooltip;
