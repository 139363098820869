import siNumber from 'si-number';

export const formatNbToSI = (number: string | number, options?: any) => {
    let nb = number;
    if (typeof number === 'string') nb = parseFloat(number);

    const opts = options ?? { decimal: '.', precision: 2, thousands: true };

    return nb === 0 ? nb : siNumber(nb, opts);
};
