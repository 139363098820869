import React from 'react';
import Logo from './Logo';
import MediaList from './MediaList';
import styled from 'styled-components';
import { Navbar } from 'react-bootstrap';
import ConnectedInfo from './ConnectedInfo';
import { space } from 'styled-system';
import { between, up } from 'styled-breakpoints';

const StyledBootstrapNavbar = styled(Navbar)<Navbar>`
    min-height: 282px;
    background-color: transparent;
    position: relative;
    align-items: start !important;
    flex-direction: column;
    ${up('sm')} {
        flex-direction: row;
    }
    ${space}
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    ${up('sm')} {
        margin-left: auto;
    }
    ${between('xs', 'sm')} {
        width: 100%;
    }
`;

interface OilerNavProps {
    showWalletBalance?: boolean;
}

const OilerNav = ({ showWalletBalance }: OilerNavProps) => {
    return (
        <StyledBootstrapNavbar expand={'lg'} px={3} mb={5}>
            <Logo />
            <Wrapper>
                <MediaList className="d-none d-md-flex" />
                <ConnectedInfo walletBalance={showWalletBalance} />
            </Wrapper>
        </StyledBootstrapNavbar>
    );
};

export default OilerNav;
