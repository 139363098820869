import invariant from 'tiny-invariant';
import { Contract, ethers } from 'ethers';
import Staking from '@abis/Staking.json';
import ERC20 from '@abis/ERC20.json';
import { STAKING_ADDRESS } from '@config/addressBook';
import { Contracts, ContractType } from './contracts-types';

class ContractsProvider {
    private chainId: number;
    private readonly contracts: Contracts;

    private provider: ethers.providers.Web3Provider;
    private signer: ethers.providers.JsonRpcSigner;

    constructor() {
        invariant(
            process.env.REACT_APP_CHAIN_ID,
            'REACT_APP_CHAIN_ID is required in env',
        );
        this.chainId = parseInt(process.env.REACT_APP_CHAIN_ID);
        this.contracts = {
            [this.chainId]: {},
        };

        // @ts-ignore
        this.provider = new ethers.providers.Web3Provider(window?.ethereum);
        this.signer = this.provider.getSigner();
    }

    public getProvider(): ethers.providers.Web3Provider {
        return this.provider;
    }

    public async getChainId(): Promise<number> {
        return this.chainId;
    }

    private async _findOrCreate(
        addressOrName: string,
        contractInterface: ethers.ContractInterface,
        signerOrProvider?:
            | ethers.providers.Provider
            | ethers.Signer
            | undefined,
    ) {
        if (!this.contracts[this.chainId][addressOrName]) {
            this.contracts[this.chainId][addressOrName] = new ethers.Contract(
                addressOrName,
                contractInterface,
                signerOrProvider,
            );
        }
        return this.contracts[this.chainId][addressOrName];
    }

    public async findOrCreate(
        ct: ContractType,
        contractAddress?: string,
    ): Promise<Contract> {
        switch (ct) {
            case ContractType.Staking:
                return this._findOrCreate(
                    STAKING_ADDRESS,
                    Staking.abi,
                    this.signer,
                );
            case ContractType.Collateral:
                if (!process.env.REACT_APP_COLLATERAL_ADDRESS) {
                    console.error('REACT_APP_COLLATERAL_ADDRESS not set');
                }
                return this._findOrCreate(
                    process.env.REACT_APP_COLLATERAL_ADDRESS!,
                    ERC20.abi,
                    this.signer,
                );
            case ContractType.LPToken:
                invariant(contractAddress, 'Contract address is required');
                return this._findOrCreate(
                    contractAddress,
                    ERC20.abi,
                    this.signer,
                );
            default:
                throw new Error(`Invalid contract type: ${ct}`);
        }
    }

    public async getAccount(): Promise<string> {
        return this.signer?.getAddress();
    }
}

// Singleton instance
let instance: ContractsProvider | null = null;

export const getContractsProvider = () => {
    instance = instance ?? new ContractsProvider();
    return instance;
};
