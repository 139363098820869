import { selectFirebase } from '@features/firebase/firebaseSlice';
import { formatUnits } from 'ethers/lib/utils';
import { useSelector } from 'react-redux';
import { BigNumber as BNLegacy } from 'bignumber.js';

export const useTVL = () => {
    const { currentValues } = useSelector(selectFirebase);
    const {
        oilPrice,
        tokenReserves,
        collateralReserves,
        lpTokensLocked,
        totalSupply,
    } = currentValues;
    if (
        !oilPrice ||
        !tokenReserves ||
        !collateralReserves ||
        !lpTokensLocked ||
        !totalSupply
    ) {
        console.warn('Current values are not enough to compute TVL');
        return null;
    }

    const liquidityPoolValue = new BNLegacy(collateralReserves).multipliedBy(2);
    const tvl = new BNLegacy(formatUnits(lpTokensLocked, 18))
        .dividedBy(formatUnits(totalSupply, 18).toString())
        .multipliedBy(liquidityPoolValue);
    return tvl;
};
