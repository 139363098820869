import { createGlobalStyle, ThemeProps } from 'styled-components';
import backgroundBlack from './themes/img/dark-bg.png';

export const OilerGlobal = createGlobalStyle`
    body {
        background-image: url(${backgroundBlack});
        overflow-x: hidden;
        font-family: 'Montserrat', sans-serif;
        font-weight: ${(props: ThemeProps<any>) =>
            props.theme.fontWeight.medium};
    }
    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    ::-webkit-scrollbar-track {
      background: #312e22;
    }
    ::-webkit-scrollbar-thumb {
      background-color: #f3e7a9;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #928b65;
    }
`;
