// TODO: should be changed when gitbook is ready
export const OILER_GITBOOK_URL = 'https://docs.oiler.network';
export const TWITTER_URL = 'https://twitter.com/OilerNetwork';
export const TELEGRAM_URL = 'https://t.me/oilernetwork';
export const DISCORD_URL = 'https://discord.gg/qd5AAJPBsq';
export const EMAIL = 'support@oiler.network';
export const MEDIUM_URL = 'https://medium.com/oiler-network';
export const LINKEDIN_URL = 'https://www.linkedin.com/company/oiler-network/';
export const DEFIPULSE_URL = 'https://defipulse.com/';
export const GITHUB_URL = 'https://github.com/oilernetwork';
export const ETHERSCAN_URL = 'https://ropsten.etherscan.io';
