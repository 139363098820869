export const footerLinks = [
    {
        name: 'Terms of Service',
        url:
            'https://docs.oiler.network/oiler-network/legal/terms-and-conditions',
    },
    {
        name: 'Legal & Risk Disclosure',
        url:
            'https://docs.oiler.network/oiler-network/token/legal-notice-and-risk-disclosure-statement',
    },
    {
        name: 'Privacy Policy',
        url: 'https://docs.oiler.network/oiler-network/token/privacy-policy',
    },
];
