import { BigNumberish } from 'ethers';

export enum StakeUserStatus {
    NOT_PARTICIPATED = 0,
    NEEDS_TO_PRESS_CEMENT = 1,
    NEEDS_TO_PRESS_HARVEST = 2,
    CAN_WITHDRAW = 3,
    WITHDRAWN_ALL = 4,
}

export interface StakingUserData {
    grantedTokens: BigNumberish;
    releasedTokens: BigNumberish;
    rewardPointsEarned: BigNumberish;
    vestedAmount: BigNumberish;
    harvestShare: number;
    harvest: BigNumberish;
    withdrawn: BigNumberish;
    vestingDuration: BigNumberish;
    vestingDurationProportion: number;
    stakes: BigNumberish[];
    status: StakeUserStatus;
}

export interface ReservoirState {
    reservoir: BigNumberish;
    APY: BigNumberish | undefined;
    totalRewardPoints: string;
    rewardPointsEarned: string;
    harvestShare: number;
    estimatedHarvest: string;
    stakingFundAmount: string;
    userStake: any;
    grantedTokens: BigNumberish;
    harvestableOil: BigNumberish;
    withdrawnAmount: BigNumberish;
    withdrawableAmount: BigNumberish;
    vestingDuration: BigNumberish;
    vestedAmount: BigNumberish;
    status: StakeUserStatus;
}

export const initialState: ReservoirState = {
    reservoir: '0',
    APY: undefined,
    totalRewardPoints: '',
    rewardPointsEarned: '',
    harvestShare: 0,
    estimatedHarvest: '',
    stakingFundAmount: '',
    userStake: {},
    grantedTokens: 0,
    harvestableOil: 0,
    withdrawnAmount: 0,
    withdrawableAmount: 0,
    vestingDuration: 0,
    vestedAmount: 0,
    status: -1,
};
