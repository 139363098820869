import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { getNameFromNetId, Network } from '@utils/getNameFromNetId';
import { initialState } from './types';
import { getBlockDuration } from '@utils/get-block-duration';
import { ContractType } from '@services/contracts-types';
import { getContractsProvider } from '@services/contracts-provider';
import invariant from 'tiny-invariant';

export const requestAccountsAsync = createAsyncThunk(
    'wallet/requestAccounts',
    async () => {
        const accounts = await window?.ethereum?.request({
            method: 'eth_requestAccounts',
        });
        if (accounts.length && typeof accounts[0] === 'string') {
            return accounts[0];
        }
        return '';
    },
);

export const initNetworkAsync = createAsyncThunk(
    'wallet/initNetwork',
    async () => {
        const provider = getContractsProvider()?.getProvider();
        if (!provider) return Network.Unknown;
        const { chainId } = await provider.getNetwork();
        return (
            Network[getNameFromNetId(chainId) as keyof typeof Network] ??
            Network.Unsupported
        );
    },
);

export const fetchBalancesAsync = createAsyncThunk(
    'wallet/fetchBalances',
    async (address: string, { getState }) => {
        const { stakingConstants } = (getState() as any).firebase;
        const { LPTokenAddress } = stakingConstants;
        invariant(LPTokenAddress, 'No LPTokenAddress set');

        const contractsProvider = getContractsProvider();
        const LPTokenContract = await contractsProvider.findOrCreate(
            ContractType.LPToken,
            LPTokenAddress,
        );
        const collateralContract = await contractsProvider.findOrCreate(
            ContractType.Collateral,
        );

        const LPBalance = await LPTokenContract.balanceOf(address);
        const collateralBalance = await collateralContract.balanceOf(address);

        return {
            LPBalance: LPBalance?.toString(),
            USDCBalance: collateralBalance?.toString(),
        };
    },
);

export const fetchStakingProgramEndInDays = createAsyncThunk(
    'staking/fetchStakingProgramEndBlock',
    async (_, { getState }) => {
        const { currentValues, stakingConstants } = (getState() as any)
            .firebase;

        const currentBlock = currentValues.blockNumber;
        const endBlock = stakingConstants.stakingEndBlock;
        if (!currentBlock || !endBlock) return 0;

        const averageBlockDurationSeconds = getBlockDuration();
        const stakingEndsInDays =
            ((endBlock - currentBlock) * averageBlockDurationSeconds) /
            parseInt(process.env.REACT_APP_DAY_DURATION_IN_SECS!);
        return {
            stakingEndsInDays,
            stakingEndBlock: endBlock,
        };
    },
);

export const wallet = createSlice({
    name: 'wallet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(requestAccountsAsync.pending, (state) => {
                state.loading.account = true;
            })
            .addCase(requestAccountsAsync.fulfilled, (state, action) => {
                state.loading.account = false;
                state.isConnected = true;
                state.account = action.payload;
            })
            .addCase(initNetworkAsync.pending, (state) => {
                state.loading.network = true;
            })
            .addCase(initNetworkAsync.rejected, (state) => {
                state.loading.network = false;
                state.network = Network.Unknown;
            })
            .addCase(initNetworkAsync.fulfilled, (state, action) => {
                state.loading.network = false;
                state.network = action.payload;
            })
            .addCase(fetchBalancesAsync.rejected, (state) => {
                state.loading.balances = false;
                state.balances.LP = '0';
                state.balances.USDC = '0';
            })
            .addCase(fetchBalancesAsync.fulfilled, (state, action) => {
                state.loading.balances = false;
                state.balances.LP = action.payload.LPBalance;
                state.balances.USDC = action.payload.USDCBalance;
            })
            .addCase(fetchStakingProgramEndInDays.rejected, (state) => {
                state.stakingEndsInDays = 0;
                state.stakingEndBlock = 0;
                state.loading.stakingEndsInDays = false;
            })
            .addCase(
                fetchStakingProgramEndInDays.fulfilled,
                (state, action) => {
                    // @ts-ignore
                    state.stakingEndsInDays = action.payload.stakingEndsInDays;
                    // @ts-ignore
                    state.stakingEndBlock = action.payload.stakingEndBlock;
                    state.loading.stakingEndsInDays = false;
                },
            );
    },
});

export const selectWallet = (state: RootState) => state.wallet;

export default wallet.reducer;
