import React from 'react';
import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import MediaList from '@oilerKit/OilerNav/MediaList';

const Footer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 100px;
    gap: 5px;
`;

const FooterText = styled.div`
    display: flex;
    ${down('md')} {
        flex-direction: column;
    }
    justify-content: center;
    align-items: center;
    padding: 15px 10px;
    background: ${(props) => props.theme.white[500]};
    width: 100%;
    gap: 10px;

    a,
    a:hover {
        font-size: inherit;
        font-weight: inherit;
        padding: 0;
        color: black;
        text-decoration: none;
    }
    ${down('xs')} {
        flex-direction: column;
    }
`;

interface OilerFooterProps {
    links?: any[];
}

const OilerFooter = (props: OilerFooterProps) => {
    const { links } = props;

    const currentYear = new Date().getFullYear();
    return (
        <Footer>
            <MediaList className="d-md-none align-self-center" />
            <FooterText>
                <span>&#169; Copyright {currentYear} by Oiler</span>
                {links
                    ? links?.map((link: any) => (
                          <a
                              href={link.url}
                              key={link.url}
                              target="_blank"
                              rel="noopener noreferrer"
                          >
                              <span>&#8729;</span>
                              {link.name}
                          </a>
                      ))
                    : null}
            </FooterText>
        </Footer>
    );
};

export default OilerFooter;
