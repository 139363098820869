import React from 'react';
import ReactDOM from 'react-dom';
import '@oilerKit/fonts.css';
import { Provider } from 'react-redux';
import { rootReducer } from './store';
import App from './App';
import {
    combineReducers,
    configureStore,
    getDefaultMiddleware,
} from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: ['transactions'], // TODO: test if we can allow more here
};

const persistedReducer = persistReducer(
    persistConfig,
    combineReducers(rootReducer),
);
const storeToPersist = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
    }),
});

const persistor = persistStore(storeToPersist);

ReactDOM.render(
    <React.StrictMode>
        <Provider store={storeToPersist}>
            <PersistGate loading={null} persistor={persistor}>
                <App />
            </PersistGate>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);
