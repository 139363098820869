import styled, { css, keyframes } from 'styled-components';
import React from 'react';
import { ToastProps } from 'react-toast-notifications';

const errorProperties = css`
    background: ${(props) => props.theme.danger['500']};
    color: ${(props) => props.theme.danger['100']};
`;

const successProperties = css`
    background: ${(props) => props.theme.success['500']};
    color: ${(props) => props.theme.success['100']};
`;

const slideLeft = keyframes`
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(-10px);
            transform: translateX(-10px);
  }
`;

const StyledOilerToast = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    width: 350px;
    background: ${(props) => props.theme.primary['500']};
    color: ${(props) => props.theme.primary['900']};
    animation: ${slideLeft} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    ${(props: any) => (props.appearance === 'error' ? errorProperties : '')};
    ${(props: any) =>
        props.appearance === 'success' ? successProperties : ''};
`;

const successColor = css`
    background: ${(props) => props.theme.success['600']};
`;
const errorColor = css`
    background: ${(props) => props.theme.danger['600']};
`;

const Icon = styled.div<{ appearance: string }>`
    background: ${(props) => props.theme.primary['600']};
    ${(props: any) => (props.appearance === 'error' ? errorColor : '')};
    ${(props: any) => (props.appearance === 'success' ? successColor : '')};
    width: 30px;
    height: inherit;
`;

const Content = styled.div`
    padding: 10px 25px;
`;

const OilerToast = (props: ToastProps) => {
    const { children, appearance } = props;
    return (
        <StyledOilerToast {...props}>
            <Icon appearance={appearance} />
            <Content> {children}</Content>
        </StyledOilerToast>
    );
};

export default OilerToast;
