import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { initialState, Transaction, TxState, TxType } from './types';

export const addTransactionAsync = createAsyncThunk(
    'transactions/addTransaction',
    async (payload: any, thunkApi) => {
        thunkApi.dispatch(updateTransactionAsync(payload));
        return {
            type: payload.type || TxType.Unknown,
            state: TxState.Pending,
            hash: payload.hash ?? '',
            url: payload.hash
                ? `${process.env.REACT_APP_ETHERSCAN_URL}/tx/${payload.hash}`
                : '',
            nonce: payload.nonce ?? -1,
            chainId: payload.chainId ?? -1,
        } as Transaction;
    },
);

export const updateTransactionAsync = createAsyncThunk(
    'transactions/updateTransaction',
    async (payload: any) => {
        const tx = await payload.wait();
        return {
            type: payload.type || TxType.Unknown,
            state: tx.status === 1 ? TxState.Success : TxState.Fail,
            hash: tx.transactionHash ?? '',
            url: tx.transactionHash
                ? `${process.env.REACT_APP_ETHERSCAN_URL}/tx/${tx.transactionHash}`
                : '',
            nonce: payload.nonce ?? -1,
            chainId: payload.chainId ?? -1,
        } as Transaction;
    },
);

export const transactions = createSlice({
    name: 'transactions',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(addTransactionAsync.fulfilled, (state, action) => {
                state.transactions = [action.payload, ...state.transactions];
            })
            .addCase(updateTransactionAsync.fulfilled, (state, action) => {
                const targetIdx = state.transactions.findIndex(
                    (tx: Transaction) => tx.hash === action.payload.hash,
                );
                if (targetIdx !== -1)
                    state.transactions[targetIdx] = action.payload;
                else console.error('Could not update tx', action.payload);
            });
    },
});

export const selectTransactions = (state: RootState) => state.transactions;

export default transactions.reducer;
