import React, { useState } from 'react';
import TransactionsList from '@components/TransactionsList/TransactionsList';
import { useSelector } from 'react-redux';
import { selectWallet } from '@features/wallet/walletSlice';
import styled from 'styled-components';
import OilerButton from '@oilerKit/OilerButton';
import OilerTypography from '@oilerKit/OilerTypography';
import { selectTransactions } from '@features/transactions/transactionsSlice';
import { ethers } from 'ethers';

const Wrapper = styled.div`
    width: 160px;
`;

const BalanceLabel = styled.div`
    color: ${(props) => props.theme.white[500]};
`;

const COLLATERAL = process.env.REACT_APP_COLLATERAL_ADDRESS;
const OILER = process.env.REACT_APP_OILER_ADDRESS;

interface BalanceProps {
    walletBalance?: boolean;
}

const Balance = ({ walletBalance }: BalanceProps) => {
    if (!COLLATERAL || !OILER)
        throw new Error(
            'REACT_APP_COLLATERAL_ADDRESS or REACT_APP_OILER_ADDRESS not set',
        );
    const [modalShow, setModalShow] = useState<boolean>(false);
    const { isConnected, balances } = useSelector(selectWallet);
    const { transactions } = useSelector(selectTransactions);

    const usdcFormatted = ethers.utils.formatUnits(balances.USDC || '0', 6);

    return (
        <Wrapper>
            {isConnected && (
                <>
                    {walletBalance ? (
                        <>
                            <BalanceLabel>Your wallet balance</BalanceLabel>
                            <OilerTypography>
                                {usdcFormatted} USDC
                            </OilerTypography>
                        </>
                    ) : (
                        <OilerButton
                            type={'outlined'}
                            href={`https://app.uniswap.org/#/add/v2/${COLLATERAL}/${OILER}`}
                        >
                            ADD LIQUIDITY
                        </OilerButton>
                    )}

                    <OilerButton
                        mt={35}
                        fullWidth
                        disabled={transactions.length <= 0}
                        onClick={() => setModalShow(true)}
                    >
                        TRANSACTIONS
                    </OilerButton>
                    <TransactionsList
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                    />
                </>
            )}
        </Wrapper>
    );
};

export default Balance;
