/**
 *
 * @returns the average time to mine a new block.
 */
export const getBlockDuration = () => {
    if (!process.env.REACT_APP_AVG_BLOCK_TIME)
        console.warn('Please set AVG_BLOCK_TIME in .env - Using 4 as fallback');

    const blockDuration = process.env.REACT_APP_AVG_BLOCK_TIME
        ? parseFloat(process.env.REACT_APP_AVG_BLOCK_TIME)
        : 4;
    return blockDuration;
};
