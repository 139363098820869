import React, { Suspense, useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import { OilerGlobal } from '@oilerKit/OilerGlobal';
import styled, { ThemeProvider } from 'styled-components';
import { darkTheme } from '@oilerKit/themes/dark';
import OilerNav from '@oilerKit/OilerNav/OilerNav';
import OilerContainer from '@oilerKit/OilerContainer';
import InfoRow from '@components/InfoRow/InfoRow';
import DrillingRow from '@components/DrillingRow/DrillingRow';
import OilerFooter from '@oilerKit/OilerFooter';
import { OilerToastProvider } from '@oilerKit/OilerToast/OilerToastProvider';
import { footerLinks } from '@config/footerLinks';
import { FirebaseListener } from '@components/Firebase/Listener';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectMessenger,
    startupCheckAsync,
} from '@features/contracts-interactions/messengerSlice';
import { DrillingState } from '@features/contracts-interactions/types';
import StakingSoon from '@components/StakingSoon';
import { selectFirebase } from '@features/firebase/firebaseSlice';
import ErrorBoundary from '@components/ErrorBoundary';
import OilerIcon from '@oilerKit/OilerIcon';
import pumpJack from '@oilerKit/assets/gif/pump-jack.gif';
import { selectWallet } from '@features/wallet/walletSlice';
import { getStakingUserData } from '@features/reservoir/reservoirSlice';

const ContentWrapper = styled.div`
    padding-left: 16px;
    padding-right: 16px;
`;

const AppWrapper = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
`;

function App() {
    const [theme] = useState(darkTheme);

    const { account } = useSelector(selectWallet);
    const { view } = useSelector(selectMessenger);
    const { loading, currentValues } = useSelector(selectFirebase);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!account) {
            return;
        }

        dispatch(getStakingUserData(account));
    }, [dispatch, account, currentValues.blockNumber]);

    useEffect(() => {
        dispatch(startupCheckAsync());
    }, [dispatch, loading]);

    const stakingNotStarted = view === DrillingState.NotStarted;
    return (
        <Suspense
            fallback={
                <div
                    style={{
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                    }}
                >
                    <OilerIcon icon={pumpJack} height={40} />
                </div>
            }
        >
            <ThemeProvider theme={theme}>
                <OilerToastProvider>
                    <OilerGlobal />
                    <AppWrapper>
                        <OilerContainer>
                            <ErrorBoundary>
                                <OilerNav showWalletBalance />
                                <ContentWrapper>
                                    {stakingNotStarted ? (
                                        <StakingSoon />
                                    ) : (
                                        <>
                                            <InfoRow />
                                            <DrillingRow />
                                        </>
                                    )}
                                </ContentWrapper>
                            </ErrorBoundary>
                        </OilerContainer>
                        <OilerFooter links={footerLinks} />
                        <FirebaseListener />
                    </AppWrapper>
                </OilerToastProvider>
            </ThemeProvider>
        </Suspense>
    );
}

export default App;
