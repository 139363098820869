import React from 'react';
import styled, { css } from 'styled-components';
import { Col, Row } from 'react-bootstrap';
import pumpJack from '@oilerKit/assets/gif/pump-jack.gif';
import staking from '@assets/staking-soon/staking.svg';
import program from '@assets/staking-soon/program.svg';
import will from '@assets/staking-soon/will.svg';
import start from '@assets/staking-soon/start.svg';
import soon from '@assets/staking-soon/soon.svg';
import OilerIcon from '@oilerKit/OilerIcon';
import OilerTypography from '@oilerKit/OilerTypography';

const StyledRow = styled(Row)`
    margin: 0;
    padding: 40px 10px;
    border: 2px solid ${(props) => props.theme.primary['500']};
    background: ${(props) => props.theme.black[500]};
`;

const properties = css`
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    align-content: center;
    justify-content: center;
`;

const StyledCol = styled(Col)`
    ${properties}
`;

const Title = styled.div`
    ${properties}
`;

const StakingSoon = () => {
    return (
        <StyledRow>
            <StyledCol md={8}>
                <Title>
                    <OilerIcon icon={staking} height={30} />
                    <OilerIcon icon={program} height={30} />
                    <OilerIcon icon={will} height={30} />
                    <OilerIcon icon={start} height={30} />
                    <OilerIcon icon={soon} height={30} />
                </Title>
                <OilerTypography italic>— You know the drill.</OilerTypography>
            </StyledCol>
            <StyledCol md={4}>
                <OilerIcon alt="Pumpjack" icon={pumpJack} height={200} />
            </StyledCol>
        </StyledRow>
    );
};

export default StakingSoon;
