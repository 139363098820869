import React from 'react';
import styled from 'styled-components';
import OilerTypography from '@oilerKit/OilerTypography';
import { Transaction } from '@features/transactions/types';
import { formatAddr } from '@utils/formatAddr';
import { down } from 'styled-breakpoints';

const StyledTransactionDescription = styled.div`
    display: flex;
    align-items: center;
    ${down('sm')} {
        flex-direction: column;
        align-items: flex-start;
    }
`;

const Action = styled.div`
    min-width: 100px;
`;

const Type = styled(OilerTypography)`
    ${down('sm')} {
        max-width: 100px;
    }
`;

interface TransactionDescriptionProps {
    tx: Transaction;
}

const TransactionDescription = ({ tx }: TransactionDescriptionProps) => {
    return (
        <StyledTransactionDescription>
            <Action>
                <OilerTypography weight={'bold'} uppercase>
                    {tx.state.toString()}
                </OilerTypography>
            </Action>
            <div>
                <Type weight={'bold'}>
                    {tx.type.toString().replace('_', ' ')} |{' '}
                </Type>
                <OilerTypography>
                    {formatAddr(tx.hash as string)}
                </OilerTypography>
            </div>
        </StyledTransactionDescription>
    );
};

export default TransactionDescription;
