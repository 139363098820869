import { BigNumberish } from '@ethersproject/bignumber';
import { utils } from 'ethers';
import { getLockingPeriodInBlocks } from '@lib/math/calculations/getLockingPeriodInBlocks';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { addTransactionAsync } from '@features/transactions/transactionsSlice';
import { TxType } from '@features/transactions/types';
import { fetchBalancesAsync } from '@features/wallet/walletSlice';
import { getContractsProvider } from '@services/contracts-provider';
import { ContractType } from '@services/contracts-types';

interface LockTokensProps {
    inputAmount: BigNumberish;
    lockedDays: number;
}

export const lockTokensAsync = createAsyncThunk(
    'drill/start',
    async ({ inputAmount, lockedDays }: LockTokensProps, thunkApi) => {
        const contractsProvider = getContractsProvider();
        const stakingContract = await contractsProvider.findOrCreate(
            ContractType.Staking,
        );

        const lockingPeriodInBlocks = getLockingPeriodInBlocks(lockedDays);

        const lockTx = await stakingContract.functions.lockTokens(
            utils.parseEther(inputAmount.toString()),
            lockingPeriodInBlocks,
        );
        thunkApi.dispatch(
            addTransactionAsync({ ...lockTx, type: TxType.Drill }),
        );
        await lockTx.wait();

        const userAddr = await contractsProvider.getAccount();
        thunkApi.dispatch(fetchBalancesAsync(userAddr));
    },
);
