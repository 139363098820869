import { Contract } from 'ethers';

export enum ContractType {
    Staking = 'Staking',
    LPToken = 'LPToken',
    Collateral = 'USDC',
}

export type Contracts = {
    [chainId: number]: {
        [contractAddress: string]: Contract;
    };
};
