import { BigNumber, BigNumberish } from '@ethersproject/bignumber';

export function computeHarvestShare(
    totalRewardPoints: BigNumberish,
    rewardPointsEarned: BigNumberish,
): number {
    return totalRewardPoints?.toString() !== '0'
        ? Number(rewardPointsEarned?.toString()) /
              Number(totalRewardPoints?.toString())
        : 0;
}

export function getExpectedHarvest(
    userRewardPoints: BigNumberish,
    totalRewardPoints: BigNumberish,
    stakingFundAmount: BigNumberish,
): BigNumber {
    if (totalRewardPoints?.toString() === '0') {
        return userRewardPoints?.toString() !== '0'
            ? BigNumber.from(stakingFundAmount)
            : BigNumber.from(0);
    }

    const BNUserRewardPoints = BigNumber.from(userRewardPoints?.toString());

    return BNUserRewardPoints.mul(
        BigNumber.from(stakingFundAmount?.toString()),
    ).div(
        BigNumber.from(totalRewardPoints?.toString()).add(BNUserRewardPoints),
    );
}
