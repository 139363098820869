import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUI } from '@features/ui/uiSlice';
import { useOilerToast } from './OilerToastProvider';

export const ToastListener = () => {
    const toast = useSelector(selectUI);
    const { addToast } = useOilerToast();

    useEffect(() => {
        if (toast.content) {
            addToast(toast.content, toast.options);
        }
    }, [addToast, toast]);

    return null;
};
