import React, { useEffect, useMemo } from 'react';
import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import OilerIcon from '../../../oilerKit/OilerIcon';
import oilBarrels from '../../../oilerKit/assets/icons/oil-barrels.svg';
import OilerTypography from '../../../oilerKit/OilerTypography';
import OilerButton from '../../../oilerKit/OilerButton';
import { space } from 'styled-system';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchStakingProgramEndInDays,
    selectWallet,
} from '@features/wallet/walletSlice';
import { selectFirebase } from '@features/firebase/firebaseSlice';
import {
    buildStepsAsync,
    selectMessenger,
} from '@features/contracts-interactions/messengerSlice';
import { DrillingState } from '@features/contracts-interactions/types';
import { selectReservoir } from '@features/reservoir/reservoirSlice';
import { OilerAlert } from '@oilerKit/OilerAlert';
import DrillingProgressionTimer from '@components/DrillingRow/DrillingStarted/DrillingProgressionTimer';
import OilerTooltip from '@oilerKit/OilerTooltip';

const StyledCol = styled(Col)`
    border: 2px solid ${(props) => props.theme.primary['500']};
    border-top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    ${space}
`;

const Description = styled.div`
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Days = styled.div`
    display: flex;
    align-items: center;
`;

const HarvestableOilWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Harvestable = () => {
    const dispatch = useDispatch();

    const { loading: firebaseLoading, currentValues } =
        useSelector(selectFirebase);

    const { stakingEndsInDays, loading } = useSelector(selectWallet);
    const { view } = useSelector(selectMessenger);
    const { harvestableOil } = useSelector(selectReservoir);

    useEffect(() => {
        if (!firebaseLoading) {
            dispatch(fetchStakingProgramEndInDays());
        }
    }, [dispatch, firebaseLoading, currentValues.blockNumber]);

    const formattedDays = useMemo(() => {
        let formatted = '';
        if (!loading.stakingEndsInDays && stakingEndsInDays && !firebaseLoading)
            formatted = Math.abs(Math.floor(stakingEndsInDays)).toString();
        else return '---';
        return formatted;
    }, [stakingEndsInDays, firebaseLoading, loading.stakingEndsInDays]);

    const disableHarvest =
        view !== DrillingState.Harvest || Number(harvestableOil) <= 0;

    return (
        <StyledCol md={4} py={4}>
            <OilerIcon alt="Barrels" icon={oilBarrels} height={130} />

            {view !== DrillingState.Over && view !== DrillingState.Harvest && (
                <HarvestableOilWrapper>
                    <OilerTypography>Harvestable OIL</OilerTypography>
                    <OilerTypography variant={'h4'} weight={'bold'}>
                        ---
                    </OilerTypography>
                </HarvestableOilWrapper>
            )}
            <OilerTooltip
                title="You don't have anything to Harvest"
                placement="top"
                id="Harvest tooltip"
                show={disableHarvest}
            >
                <OilerButton
                    disabled={disableHarvest}
                    onClick={() => {
                        dispatch(
                            buildStepsAsync({ interactionName: 'HARVEST' }),
                        );
                    }}
                    style={{ pointerEvents: disableHarvest ? 'none' : 'auto' }}
                    fullWidth
                >
                    HARVEST
                </OilerButton>
            </OilerTooltip>

            <Description>
                <OilerTypography
                    weight={'light'}
                    align={'center'}
                    display={'block'}
                >
                    OILER Expedition{' '}
                    {stakingEndsInDays <= 0 ? 'ended' : 'will end in'}
                </OilerTypography>
                <Days>
                    {Math.abs(stakingEndsInDays) < 1 ? (
                        <DrillingProgressionTimer
                            stakeDaysLeft={stakingEndsInDays}
                        />
                    ) : (
                        <>
                            <OilerTypography
                                weight={'bold'}
                                variant={'h4'}
                                mr={1}
                            >
                                {' '}
                                {formattedDays}
                            </OilerTypography>
                            <OilerTypography variant={'caption'}>
                                DAY
                                {Math.abs(Math.floor(stakingEndsInDays)) > 1
                                    ? 'S'
                                    : ''}
                                {stakingEndsInDays <= 0 ? ' AGO' : ''}
                            </OilerTypography>
                        </>
                    )}
                </Days>
                <OilerAlert variant={'info'} mt={30} show={!disableHarvest}>
                    <>
                        {view === DrillingState.Over &&
                            'You need to CEMENT your stake to Harvest'}
                        {view === DrillingState.Harvest &&
                            'Press HARVEST to harvest your OIL reward'}
                        {view !== DrillingState.Over &&
                            view !== DrillingState.Harvest &&
                            'After that you will be able to extract your OIL during the 100-day extraction period'}
                    </>
                </OilerAlert>
            </Description>
        </StyledCol>
    );
};

export default Harvestable;
