import { BigNumber, BigNumberish } from '@ethersproject/bignumber';

export function calculateStakingRewardPoints(
    tokenAmount: BigNumberish,
    lockingPeriodInBlocks: BigNumberish,
): BigNumber {
    if (
        !tokenAmount ||
        tokenAmount.toString() === '0' ||
        !lockingPeriodInBlocks ||
        lockingPeriodInBlocks.toString() === '0'
    )
        return BigNumber.from('0');

    const stakingRewardPoints = BigNumber.from(tokenAmount).mul(
        BigNumber.from(lockingPeriodInBlocks).pow(2),
    );

    return stakingRewardPoints;
}
