import React from 'react';
import styled from 'styled-components';
import Balance from './Balance';
import MetaMask from './MetaMask';
import { up } from 'styled-breakpoints';
import { gap } from '@oilerKit/OilerPolyfill';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 38px;
    flex-direction: column;
    //gap: 40px;
    ${gap(40, 'b')}
    ${up('sm')} {
        //gap: 0px;
        flex-direction: row;
        min-width: 425px;
        ${gap(40, 'r')}
    }
`;

interface WalletInfoProps {
    walletBalance?: boolean;
}

const WalletInfo = ({ walletBalance }: WalletInfoProps) => {
    return (
        <Wrapper>
            <MetaMask />
            <Balance walletBalance={walletBalance} />
        </Wrapper>
    );
};

export default WalletInfo;
