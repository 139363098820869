import React from 'react';
import styled from 'styled-components';
import { Modal, ModalProps } from 'react-bootstrap';
import OilerTypography from './OilerTypography';
import { ModalHeaderProps } from 'react-bootstrap/ModalHeader';
import OilerButton from '@oilerKit/OilerButton';
import OilerIcon from '@oilerKit/OilerIcon';
import warning from '@oilerKit/assets/icons/warning/warning.svg';
import { down } from 'styled-breakpoints';

const StyledBootstrapModal = styled(Modal)`
    color: ${(props) => props.theme.primary[500]};
    .modal-dialog {
        ${down('sm')} {
            max-width: 100%;
            margin: 0.5rem;
        }
    }

    .modal-content {
        border: 2px solid ${(props) => props.theme.primary[500]};
        border-radius: 0;
        background: ${(props) => props.theme.black[500]};
    }
    .modal-footer {
        background: ${(props) => props.theme.black[500]};
        border-top: 0;
    }
`;

const StyledBootstrapModalBody = styled(Modal.Body)`
    background: ${(props) => props.theme.black[500]};
`;

const StyledBootstrapModalHeader = styled(Modal.Header)<ModalHeaderProps>`
    background: ${(props) => props.theme.primary[500]};
    border-radius: 0;
    border: 0;
    display: flex;
    align-items: center;
    .close > span {
        text-shadow: none;
        color: ${(props) => props.theme.black[500]};
        font-size: 3rem;
        font-weight: ${(props) => props.theme.fontWeight.light};
    }
`;

const StyledBootstrapModalTitle = styled(Modal.Title)`
    display: flex;
    align-items: center;
    color: ${(props) => props.theme.black[500]};
`;

interface OilerModalProps extends ModalProps {
    children?: JSX.Element | JSX.Element[];
    title?: string;
    closeButton?: boolean;
    cancelButtonText?: string;
    confirmButtonText?: string;
    cancelButtonOnClick?: () => void;
    confirmButtonOnClick?: () => void;
    variant?: 'warning';
}

const icons = {
    warning: warning,
};

const OilerModal = (props: OilerModalProps) => {
    const {
        children,
        title,
        closeButton = false,
        cancelButtonText,
        cancelButtonOnClick,
        confirmButtonText,
        confirmButtonOnClick,
        variant,
    } = props;

    return (
        <StyledBootstrapModal {...props}>
            {title ? (
                <StyledBootstrapModalHeader closeButton={closeButton}>
                    <StyledBootstrapModalTitle>
                        {variant ? (
                            <OilerIcon
                                icon={icons[variant]}
                                height={35}
                                mr={10}
                            />
                        ) : null}
                        <OilerTypography
                            color={'black'}
                            weight={'extraBold'}
                            variant={'h3'}
                        >
                            {title}
                        </OilerTypography>
                    </StyledBootstrapModalTitle>
                </StyledBootstrapModalHeader>
            ) : null}
            <StyledBootstrapModalBody>{children}</StyledBootstrapModalBody>
            {cancelButtonText || confirmButtonText ? (
                <Modal.Footer>
                    {cancelButtonText ? (
                        <OilerButton
                            onClick={cancelButtonOnClick}
                            px={50}
                            type={'outlined'}
                        >
                            {cancelButtonText}
                        </OilerButton>
                    ) : null}
                    {confirmButtonText ? (
                        <OilerButton
                            onClick={confirmButtonOnClick}
                            px={50}
                            variant={'secondary'}
                        >
                            {confirmButtonText}
                        </OilerButton>
                    ) : null}
                </Modal.Footer>
            ) : null}
        </StyledBootstrapModal>
    );
};

export default OilerModal;
