import { ReactNode } from 'react';
import { Options } from 'react-toast-notifications';

export interface ToastState {
    content?: ReactNode;
    options?: Options;
    callback?: (id: string) => void;
}

export const initialState: ToastState = {};
