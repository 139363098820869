import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';
import { initialState } from './types';

export const firebase = createSlice({
    name: 'firebase',
    initialState,
    reducers: {
        updateStakingConstants: (state, action) => {
            state.stakingConstants = action.payload;
            state.loading = false;
        },
        updateCurrentValues: (state, action) => {
            state.currentValues = action.payload;
        },
    },
});

export const { updateStakingConstants, updateCurrentValues } = firebase.actions;

export const selectFirebase = (state: RootState) => state.firebase;

export default firebase.reducer;
