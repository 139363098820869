import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import walletReducer from '@features/wallet/walletSlice';
import reservoirReducer from '@features/reservoir/reservoirSlice';
import messengerReducer from '@features/contracts-interactions/messengerSlice';
import uiReducer from '@features/ui/uiSlice';
import transactionsReducer from '@features/transactions/transactionsSlice';
import firebaseReducer from '@features/firebase/firebaseSlice';

export const rootReducer = {
    wallet: walletReducer,
    reservoir: reservoirReducer,
    messenger: messengerReducer,
    ui: uiReducer,
    transactions: transactionsReducer,
    firebase: firebaseReducer,
};

export const store = configureStore({
    reducer: rootReducer,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
